.ant-layout-header{
    height: 80px !important;
    color: #FFFFFF !important;
    background:#065BAE !important;
    z-index: 99999 !important;
}
.ant-menu-dark,
.ant-menu-dark>.ant-menu{
    background: #065BAE !important;
    color: #ffffff !important;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-dark>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu-selected,
.ant-menu-dark>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected{
    background-color: transparent !important;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item-selected:hover,
.ant-menu-dark>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected:hover,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu-selected:hover,
.ant-menu-dark>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected:hover{
    background-color: transparent !important;
}

.header-logo{
    line-height: 14px;
    margin-right: 80px;
}
.menuIcon{
    vertical-align: middle;
    margin-right: 10px;
}
.ant-menu-dark .ant-menu-item {
    color: rgba(255, 255, 255, 0.9)
}
.ant-menu-dark.ant-menu-horizontal>.ant-menu-item-selected{
    font-weight: 800 !important;
}

.ant-select-dropdown{
    z-index: 9999;
}
.headerLanguage .ant-btn-primary{
    background: transparent !important;
}

/* 
.ant-menu-title-content{
    color: #3483cd;
}
.headerLanguage .ant-select{
    width: 85px !important;
}
.ant-select-selection-item{
    color: #FFFFFF;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-select .ant-select-arrow{
    color: #FFFFFF;
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
    background: transparent;
    border: 1px solid #82ACD6;
} */
.headerLogout{
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
}
.headerLogout img{
    vertical-align: middle;
}
.ant-layout-footer{
    padding: 15px 50px;
    background: #0559AC !important;
    color: rgba(255, 255, 255, 0.9);
}
