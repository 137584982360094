.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout-content {
  min-height: 100vh;
}

.mainContent {
  background: url('./static/image/bigImage.jpg') no-repeat;
  background-size: 100% auto;
}

.pageContainer {
  max-width: 1600px;
  padding: 80px 140px 0;
  margin: 0 auto;
}
/* 日历 */
.timeSelect {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 3px 3px 10px 0px rgba(5, 84, 161, 0.18);
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 16px;
  padding: 20px 24px;
}
.timeSelect .ant-picker{
  margin-bottom:0 !important;
}
.timeSelect .ant-picker-outlined{
  background: #FCFCFC;
}
/* .timeSelect {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 3px 3px 10px 0px rgba(5, 84, 161, 0.18);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.timeSelect .ant-row {
  flex-flow: inherit;
  flex: 1 1;
}

.timeSelect .ant-picker {
  padding: 4px 38px;
  border-radius: 2px;
  position: relative;
}

.timeSelect .ant-picker-input {
  display: block !important;
}

.timeSelect .ant-picker-input>input {
  width: 110%;
} */

/* .timeSelect .ant-picker-suffix {
  position: absolute;
  right: -26px;
  top: 4px;
} */
/* .timeSelect .ant-picker-clear{
  right: -6px !important;
} */

.startTimeBtn {
  position: relative;
}

.startTimeBtn .startSalendar {
  position: absolute;
  left: 8px;
  top: -3px;
  z-index: 1;
}

.startTimeBtn .ant-picker-outlined {
  background: #FCFCFC;
}
/* 下拉 */
.antSelect .ant-select-selector{
  background: #FCFCFC !important;
  border-radius: 2px !important;
}
.antSelect .ant-select-selector{
  text-align: left !important;
}


/* Card */
.table-card {
  text-align: left;
  border: none;
  background: #F9F9F9;
}
.category-echart{
  background: #FFF;
  box-shadow: 3px 3px 10px 0px rgba(5, 84, 161, 0.18);
}
/* 大 */
.contrast-card .ant-card-head {
  min-height: 50px;
  background: url('./static/image/tabsTitle.png') no-repeat;
  background-size: 100% 100%;
  border-bottom: none;
}
/* 小 */
.listCard .ant-card-head {
  min-height: 50px;
  background: url('./static/image/tabsIcon.png') no-repeat;
  background-size: 100% 100%;
  border-bottom: none;
}
.table-card .ant-card-body{
  padding-top:0 !important;
  padding-right: 14px !important;
}
.wordCloudIcon {
  font-size: 16px;
  position: absolute;
  right: 26px;
  top: 56px;
  z-index: 12;
}
.wordCloudIconRight{
  top: 16px;
}
.ant-message-top{
  top: 160px !important;
}

.columnChartHeight .ant-card-body{
  height: 376px;
  overflow: auto;
}
/* tips */

.textExplain .ant-btn.ant-btn-icon-only .anticon {
  font-size: 20px;
}

.textExplain .ant-btn-link {
  color: #065BAE !important;
}

.textExplain .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #065BAE !important;
}

.ant-notification .ant-notification-notice {
  background-color: #f0fbff;
  border: 1px solid #bae7fc;
  border-radius: 2px;
}

.showTips .ant-drawer-body {
  min-height: 120px;
}

.showTips .ant-drawer-content {
  overflow: initial !important;
}

.showTips .ant-drawer-content {
  height: auto !important;
}

.closeIcon {
  float: right;
  cursor: pointer;
} 

.ant-notification_tips {
  font-size: 14px;
  color: #80889e;
  margin-bottom: 12px;
}

.ant-notification_tips .ant-notification_tips-title {
  color: #3364a9;
  list-style-type: disc !important;
}

.ant-notification-topRight.ant-notification-stack>.ant-notification-notice-wrapper{
    top: 70px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
